.screen-template {
  color: black;
  .view-basic {
    .view-sidebar {
      background-color: white;
      border-left: rem(15px) solid $view_red;
      & > div {
        border-left: rem(15px) solid $view_yellow;
        border-right: rem(15px) solid $view_yellow;
      }
      .view-logo {
        & > img {
          width: calc(100% - 6rem);
          height: 200px;
        }
      }
      .time-indicator {
        background-color: $view_red;
        border-radius: 15px;
        color: white;
        .hour {
          & p {
            margin: 0;
            font-size: 50px;
            & span {
              margin-left: 0.5rem;
              margin-right: 0.5rem;
            }
          }
        }
      }
      .carousel .carousel-item {
        .card {
          background-color: transparent !important;
          box-shadow: none;
          .card-img-top {
            height: rem(170px);
          }
          .card-body {
            color: black !important;
            overflow: hidden;
            height: rem(300px);
          }
        }
      }
    }
    .view-content {
      background-color: $view_red;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .view-header {
        color: white;
        display: flex;
        justify-content: center;
        width: 80%;
        align-items: center;
        height: rem(150px);
        padding-top: 3rem;
        padding-bottom: 3rem;
        h1 {
          margin: 0;
          height: 100%;
          flex-grow: 1;
          text-align: center;
          border-radius: 15px;
          box-shadow: 0px 1px 27px 9px rgba(255, 255, 255, 0.44) inset;
          -webkit-box-shadow: 0px 1px 27px 9px rgba(255, 255, 255, 0.44) inset;
          -moz-box-shadow: 0px 1px 27px 9px rgba(255, 255, 255, 0.44) inset;
        }
      }
      .view-body {
        max-height: calc(100vh - rem(300px));
        width: 100%;
        padding: 1rem 2rem;
        background-color: white;
        flex-grow: 1;
        & video {
          border: rem(12px) solid $view_red;
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .view-footer {
        padding: .5rem 3rem;
        width: 100%;
        display: flex;
        justify-content: center;
        height: rem(150px);
        padding-top: 3rem;
        padding-bottom: 3rem;
        gap: rem(10px);
        p {
          margin: 0;
          color: white;
        }
        svg {
          * {
            fill: white;
          }
        }
      }
    }
  }
  .view-fullscreen {
    background-color: black;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
