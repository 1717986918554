.card {
    .card-video-top {
      //width: rem(250px);
        & video {
            width: 100%;
        }
    }
}

.option-description{
  
  height: 40px;
  font-size: rem(12px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-horizontal {
    
  .card-text {
    height: 90px;
    font-size: rem(12px);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
.card-add-screen{
  min-height: 50px;
  .card-body {
    display: flex;
    flex-direction: column;
  }
}

.callout.callout-info {
  color: black !important;
}
