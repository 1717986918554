.slide-option {
  .card-title {
    font-size: rem(15px);
  }
  .card-text {
    height: 30px;
    font-size: rem(10px);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.rule-time-option{
  border-radius: 5px;
  transition: background .5s linear;
  &> .icon-delete{
    display: none;
    position: absolute;
    inset: 0;
    &>svg {
      fill: white;
      height: 20px;
    }
  }
  &:hover {
    background-color: $primary-50;
    &> .icon-delete{
      display: block;
    }
  }
}
