.toast-container {
	position: fixed!important;
	top: 0;
    right: 0;
	width: rem(350px);
	
	@include media-breakpoint-down(md) {
		width: rem(300px);
	}
	
	& .toast {
		width: 100%;
	}
}