// Here you can add other styles
@import './component/card.scss';
@import './component/screenTemplates.scss';
@import './component/toast.scss';
@import './component/createScreen.scss';
@import './component/slides.view.scss';

* {
  /*font-size: 62.5%; /**es una transformacion necesaria para poder usar rem que mejoran la pag 1rem igual a 10px**/
  box-sizing: border-box;
  --cui-primary-start: $primary-start;
  --cui-primary-end: $primary-end;
  --cui-secondary-start: $secondary-start;
  --cui-secondary-end: $secondary-end;
  .btn-primary {
    --cui-btn-color: $btn-color;
    --cui-btn-hover-color: $btn-color;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba($main_orange, 1);
  padding: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba($main_brown, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba($main_brown, 0.90);
}
.cursor-pointer {
  cursor: pointer !important;
}

.form-multi-select {
  .form-multi-select-option {
    color: black;
    &.form-multi-selected {
      color: white;
    }
  }
  .dropdown-menu {
    width: 100%;
  }
}
