.slides-table {
  .card-img {
    width: 150px;
  }
  .slide-table-description p {
    margin: 0;
    height: 75px;
    font-size: rem(12px);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
